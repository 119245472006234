<template>
  <div class="main-content">

    <!-- https://twitter.com/robslasagna/status/1376338556256452610 -->

    <Exhibition3D @loader-finished="loaderFinished" />

    <div class="main-content__text">
      <p style="margin-top: -80px;">
        <b>Bored Hash Galleries</b> is a collection of 4,000 unique artworks representing galleries drawn in different styles by various artists across the globe. However, a gallery is just a shell — it cannot exist without paintings. This is a primary reason why Galleries are dynamic and powered by Hashmasks and Bored Apes.
      </p>
    </div> <!-- /.main-content__text -->

    <el-card class="card card--with-images">
      <div class="card--with-images__text">
        <h2>One Hashmask/Ape to One Gallery</h2>

        <p>
          Each Bored Hash Gallery has a special space to hang a Hashmask or Ape. This means that you can buy a Gallery only if you have a Hashmask or Bored Ape. For every Gallery, you must choose one Hashmask or Ape (that you own!) to be placed in a Gallery. Each Hashmask and Bored Ape can be placed only in one Gallery.
        </p>
      </div>

      <img class="card--with-images__image" src="/public-images/galleries-examples.gif" />
    </el-card>

    <div class="text-section">
      <h2>How it works</h2>
      <!-- <h2>How does it work?</h2> -->
      <!-- <h2>Technical Details</h2> -->

      <p>
        There are a total of 4,000 unique Galleries. Each is created by a combination of several different artists. They all have a cutout in the center dedicated to your Hashmasks and Bored Apes, so they are incomplete without Hashmasks/Apes.
      </p>

      <p>
        By buying a Gallery, you assign your Hashmask or Ape to it on-chain. As a result, each Gallery will be connected to one Hashmask or Bored Ape. Each Hashmask and Ape can be connected to only one Gallery. All Galleries are hosted on IPFS, but the script to merge a Gallery with a Hashmask/Ape is stored on the Ethereum blockchain.
      </p>

      <p>
        To ensure a fair distribution, we use the same system as Hashmasks and BAYC. The user will not know what Gallery they purchase for the first 10 days of the sale. All NFTs are already created, but not indexed yet. There is a predetermined sequence of Galleries, but which one will be the first Gallery will only be determined at the end of the sale using a random mechanism on-chain. This method guarantees a fair distribution. We also decided to limit a maximum of 5 Galleries per transaction.
      </p>

      <p>
        <!-- The distribution period begins at 17:00 on 30 June 2021 (CEST). -->
        The distribution period begins on 7 July 2021.
        After 10 days, the contribution period concludes and your Galleries will be revealed.
        <!-- After 10 days or when all the Galleries are sold (whichever is earlier),
        the contribution period concludes and your Galleries will be revealed. -->

        <!-- If unsold after the reveal phase, The sale will continue at the prevailing
        rate with two differences: (1) All portraits will be revealed, so you know which one
        you are buying and (2) there will be no bonus NCT once the 14 days are over. -->
      </p>
    </div> <!-- /.text-section How it works (Technical Details) -->

    <div class="main-content__text faq-section">
      <!-- from meebits: -->
      <!-- from meebits: -->
      <!-- from meebits: -->

      <!-- <h5>
        What are the requirements to perform the free minting?
      </h5>
      <p>
        The minting must be transacted by the account that owns the Cryptopunk or Autoglyph. The minting contract will verify ownership based on what account is requesting the mint.
      </p> -->

      <h5>
        What if I buy a Hashmask or Ape that has already been used to buy a Gallery? Can I buy a Gallery too?
      </h5>
      <p>
        <b>No.</b> There is only one Mona Lisa and it can be placed only in one gallery. The same applies for Hashmasks/Apes — each Hashmask/Ape can be hung only in one Gallery.

        <!-- <b>No.</b> Each Hashmask can be thought of as a ticket worth 1 free Meebit. Once that ticket is used, it can't be used again. You can check how many free mints are left for any punk or glyph using this page. -->
      </p>

      <h5>
        Am I exchanging my Hashmask/Ape for these new things?
      </h5>
      <p>
        <b>No!</b> You will of course still own your Hashmask/Ape at the end of this process.

        <!-- The sale contract will just track how many items you are allowed to mint based on what punks or glyphs you own now. -->
      </p>

      <h5>Can I change a Hashmask/Ape that is connected to my Gallery after buying it?</h5>
      <p>
        <!-- minting заменить на create??? -->
        <b>No.</b> By buying a Gallery, you mint an absolutely new NFT that is permanently connected to a chosen Hashmask or Ape.
      </p>

      <h5>Can I sell or transfer my Gallery to an account without any Hashmask/Ape?</h5>
      <p>
        <b>Yes!</b> Each Gallery is an absolutely new NFT, and you can do anything with it that you can do with any other NFT.
      </p>

      <h5>
        What if I transfer or sell my Hashmask/Ape after buying a Gallery?
      </h5>
      <p>
        You only need to own a Hashmask/Ape when buying. Thereafter, you could even burn your Hashmask/Ape (although you would certainly need to be crazy to do so!) and you would still be an owner of a Gallery. Its appearance will not be changed in any way.
      </p>

      <!--
      <h4>What is this?</h4> 
      <p>
          As a thank you to the community that has made the Cryptopunks and Autoglyphs what it is today, we are allowing owners to mint Meebits from our new project for free.
      </p>

      <h4>How does it work?</h4> 
      <p>
          Each Cryptopunk and Autoglyph owned allows the minting of <b>1</b> free Meebit.
      </p>

      <h4>How much do they cost?</h4> 
      <p>
          They are free. You just need to cover the Ethereum transaction fee (gas) associated with the minting transaction.
      </p>

      <h4>How long does community minting run for?</h4> 
      <p>
          One week, from the start of the public sale.
      </p>
      -->

      <!-- ^^from meebits^^ -->
      <!-- ^^from meebits^^ -->
      <!-- ^^from meebits^^ -->
    </div> <!-- /.main-content__text .faq-section -->


    <!-- Images examples section START: -->
    <div class="main-content__images-examples">
        <!-- :image="`/public-images/examples/${i + 1}-empty.png`" -->
      <TwentyTwenty
        v-for="(empty, i) in new Array(9)" :key="i"
        :is-loader-finished="isLoaderFinished"
        offset="0.5"
        :image="`/public-images/examples/${i + 1}-empty_compressed.jpg`"
        :id="i"
      />
        <!-- :before="`/public-images/examples/originals/${i + 1}-mask.png`" -->
        <!-- :after="`/public-images/examples/originals/${i + 1}-empty.png`" -->
    </div>
    <!-- ^^^Images examples section END^^^ -->


    <div class="text-section">
      <h2>Evolution of Implicit Rarity</h2>
      <!-- <h2>Implicit Rarity 2.0</h2> -->

      <p>
        Hashmasks was the first project to introduce Implicit Rarity, a revolutionary concept that many projects are now copying. However, we decided to improve it. Now, we proudly present <b>Implicit Rarity 2.0</b>. The primary issue of the original Implicit Rarity is that subcategories of explicit characteristics can be easily classified, and the most implicit traits are becoming ordinary explicit traits with such tools like Thehashnames, Hashyverse, and Mask Radar. For that reason, the implicit traits of Galleries are based on <b>the combinations</b>.
        <!-- Moreover, we do not provide any explicit traits, so Gallery values are based solely on their aesthetics. -->



        <!-- you can easily, for example, find all Hashmasks that feature a unicorn mask. -->
        <!--
          короче, расписать, что найти единорогов среди масок довольно легко, поэтому у нас Implicit Rarity базируется
          на комбинации разных аттрибутов - галлерея с одним стилем гораздо ценнея, чем галлерея с самым редким полом
          Плюс написать, что полностью отказались от Explicit Rarity и у каждой галереи редкость 0.02%, потому что
          в качестве аттрибутов мы даем тупо id'шник
        -->

        <!--
          Из дискорда:
          We cannot control the preference of the consumers, we are inherently drawn to more rare things. That is precisely the reason why we omitted many traits to redirect the attention a bit, but people seem to be reverting back to it
        -->
      </p>
    </div> <!-- /.text-section -->


    <div v-if="false" class="text-section text-section--with-line" :class="{'show-animation': true}">
      <div class="text-section--with-line__content">
        <!-- юзеры сами определяют отображать ли имя -->
        <!-- что имена теперь пишутся внутри nft // имена изменяются внутри картинки -->
        <h2>Evolution of Names</h2>
        <!-- Dynamic Rarity? -->
        <!-- <h2>The first Dynamic NFT</h2> -->


        <!-- <div class="home__guide" ref="scrollTarget">
          <div>
            <img src="http://placeholder.pics/svg/200x132">
            <p>Your Hashmask</p>
          </div>

          <i class="el-icon-arrow-right"></i>

          <div>
            <img src="http://placeholder.pics/svg/200x132">
            <p>Your Gallery</p>
          </div>
        </div> --> <!-- /.home__guide -->

        <p>
          Today, the ability to give your NFT a name is the norm. But remember, before Hashmask naming an NFT was not even on anyone's radar. The Hashmask NCT is the first name-changing token and, as such, sets an industry standard. Gallery continues this idea — you cannot set a name of your Gallery but the name of your Hashmask can become a part of your Gallery.

          <!-- <br> -->
          <!-- <br> -->
          <!-- Updating an NFT itself with the name of your Hashmask is great and it is adding dynamic to the NFT assets but NFT can be even more dynamic!  -->
          <!-- And yes, it means that also you can yourself decide a rarity of your Gallery — nobody (even creators!) knows how many will be Hashgalleries with Hashmasks names. -->
        </p>

        <p>
          After buying a Gallery you can burn 1,830 NCTs to enable displaying the name of your Hashmask inside your Gallery. This action cannot be reverted and you cannot disable displaying a name after enabling it. If you change the name of your Hashmask, then your Gallery also will be updated and will display a new name of your Hashmask.
        </p>

        <img src="http://placeholder.pics/svg/500x400" style="margin: 50px auto 0; display: block;" />
      </div> <!-- /.text-section--with-line__content -->

      <div class="text-section--with-line__line"></div>

    </div> <!-- /.text-section Evolution of Names -->

    <!-- <el-card class="card pricing-section"> -->
    <div class="pricing-section" id="pricing-section">
      <div class="text-section">
        <h3>Fair Distribution</h3>

        <p style="margin-top: 25px;">
          Now, almost any new NFT uses a bonding curve. It is a great pricing model,
          but it has its own problems. Moreover, some projects are just not suitable for
          this model. For that reason, Galleries do not have any bonding curve. Moreover,
          you can buy Galleries with ETH but also you can buy them with NCT!


          <!-- Instead, Gallery pricing is based on <b>timelock</b>. During timelock, you cannot
          transfer your NFT to any other address (and yes, this means that you cannot sell it).
          You can decide the timelock and price before purchasing — a longer timelock entails
          a lower price, and a shorter timelock entails a higher price. -->
        </p>

        <p style="margin-top: 25px;">Each Gallery costs <b>0.06 ETH</b> <i>OR</i> <b>12,000 NCT</b></p>

        <br>
        <h3>Future</h3>
        <p style="margin-top: 25px;">
          We plan to launch the first NFT lottery exclusively for the owners of Galleries —
          any Galleries owners will be able to buy a ticket for something like 0.01 ETH,
          and then one of them could win a Hashmask or Ape.
          The development of the Lottery smart contract will begin immediately after the sale of
          50% of the collection.
        </p>
      </div> <!-- / new .text-section with simple 0.08 ETH pricing -->

      <div class="text-section" v-if="false">
        <!-- <p><b>Moreover, you can buy Gallery by NCT!</b></p> -->
        <!-- Hashgalleries cannot be fully powered by Hashmasks if you could buy a Gallery only with ETH and cannout with NCT. -->

        <h3>NCT, NCT, and NCT!</h3> <!-- возможность покупки с помощью NCT -->
        <p>
          <!-- Gallery would not exist without Hashmasks. To continue that idea, we decided to add an ability to buy Hashgalleries with NCT. -->
          <!-- Yes, that is correct — you can buy Hashgalleries with ETH but also you can buy them with NCT! -->
          We are pleased to announce that you can buy Galleries with ETH but also you can buy them with NCT!
        </p>

        <h3 id="no-bonding-curve">No bonding curve</h3>
        <p>
          Now, almost any new NFT uses a bonding curve. It is a great pricing model, but it has its own problems. Moreover, some projects are just not suitable for this model. For that reason, Galleries do not have any bonding curve. Instead, Gallery pricing is based on <b>timelock</b>. During timelock, you cannot transfer your NFT to any other address (and yes, this means that you cannot sell it). You can decide the timelock and price before purchasing — a longer timelock entails a lower price, and a shorter timelock entails a higher price.
        </p>



        <ul class="pricing-section__pricing-list">
          <li>3 months timelock: <b>FREE</b> (only 50 available, 1 per account)</li>

          <li v-for="timelockValue in timelocks" :key="timelockValue">
            {{timelockValue}} timelock:

            <el-popover placement="top-start" trigger="hover" popper-class="pricing-section__popover" title="Coming soon"
            >
                <span slot="reference" class="pricing-section__blurred-text"></span>
            </el-popover>

            ETH or

            <el-popover placement="top-start" trigger="hover" popper-class="pricing-section__popover" title="Coming soon"
            >
                <span slot="reference" class="pricing-section__blurred-text"></span>
            </el-popover>

            NCT
          </li>

          <!-- <li v-for="timelock in timelockWithPricing" :key="timelock.time">
            {{ timelock.time }} timelock: {{ timelock.eth }} ETH or {{ getFormattedNctPricing(timelock.eth) }} NCT
          </li> -->

          <!--
            <li>6 months timelock: 0.05 ETH or 3,500 NCT</li>
            <li>3 months timelock: 0.1 ETH or 7,000 NCT</li>
            <li>1 month timelock: 0.3 ETH or 21,000 NCT</li>
            <li>2 weeks timelock: 0.5 ETH or 35,000 NCT</li>
            <li>No timelock: 1 ETH or 70,000 NCT</li>
          -->
        </ul>
      </div> <!-- / legacy .text-section with timelocks -->

    </div> <!-- /.pricing-section -->
    <!-- </el-card> -->


    <h1 v-if="false" style='margin: 250px 0; text-align: center; font-family: "Inconsolata", monospace; font-size: 50px;'>
      Coming Soon
    </h1>

    <BuyingSection v-else />

    <div v-if="true" style="margin: 70px 0; text-align: center;">
      <h3>Verified Smart Contract</h3>
      <ul>
        <li>
          <!-- Galleries: -->
          <a :href="`https://etherscan.io/address/${getEnv('VUE_APP_GALLERIES_ADDRESS')}`">
            {{ getEnv('VUE_APP_GALLERIES_ADDRESS') }}
          </a>
        </li>

        <div style="display: none">
          <li>
            BAYC:
            <a :href="`https://etherscan.io/address/${getEnv('VUE_APP_BAYC_ADDRESS')}`">
              {{ getEnv('VUE_APP_BAYC_ADDRESS') }}
            </a>
          </li>

          <li>
            Hashmasks:
            <a :href="`https://etherscan.io/address/${getEnv('VUE_APP_MASKS_ADDRESS')}`">
              {{ getEnv('VUE_APP_MASKS_ADDRESS') }}
            </a>
          </li>

          <li>
            NCT:
            <a :href="`https://etherscan.io/address/${getEnv('VUE_APP_NCT_ADDRESS')}`">
              {{ getEnv('VUE_APP_NCT_ADDRESS') }}
            </a>
          </li>
        </div>
      </ul>
    </div>

  </div>

</template>

<script>

import { Card, Popover} from 'element-ui';

import Exhibition3D from '@/components/Exhibition3D.vue';
import BuyingSection from '@/components/BuyingSection.vue';

// import TwentyTwenty from 'vue-twentytwenty';
import TwentyTwenty from '@/components/TwentyTwenty.vue';

export default {
  name: 'HomeEarlyAccess',
  components: {
    Exhibition3D,
    BuyingSection,

    'el-card': Card,
    'el-popover': Popover,

    TwentyTwenty,
  },
  data() {
    return {
      // nctMultipler: 80000,
      // timelockWithPricing: [
      //   {time: '6 months',    eth: 0.04,},
      //   {time: '3 months',    eth: 0.08,},
      //   {time: '1 month',     eth: 0.16,},
      //   {time: '2 weeks',     eth: 0.32,},
      //   {time: 'No',          eth: 0.64,},
      // ],
      // timelocks: ['1 month', '3 weeks', '2 weeks', '1 week', 'No'],

      isLoaderFinished: false,
    };
  }, // data

  methods: {
    loaderFinished() {
      this.isLoaderFinished = true;
    },

    getEnv(envKey) {
      return process.env[envKey];
    },

    // getFormattedNctPricing(ethPrice) {
    //   const nctPrice = ethPrice * this.nctMultipler;
    //   if (nctPrice < 1000) return nctPrice;
    //   return String(nctPrice).slice(0, -3) + "," + String(nctPrice).slice(-3);
    // },
  }, // methods
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

  .main-content__text {
    max-width: 900px;
    margin: auto;
    padding: 0 15px;
  }

  .main-content__text--big {
    // p:first-of-type {
      font-size: 21px;
      // line-height: 30px;
    // }
  }

  .main-content__images-examples {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 60px auto 100px;
    max-width: 1300px;

    .twentytwenty-container {
      // width: 400px;
      max-width: 400px;
      width: 100%;
      margin-top: 30px;
    }
  }

  .faq-section {
    p {
      margin: 0;
      font-size: 16px;
    }

    h5 {
      // font-size: 18px;
      font-size: 17px;
      margin-bottom: 5px;
      margin-top: 50px;
    }
  }

  $text-section-max-width: 900px;

  .text-section {
    max-width: $text-section-max-width;
    margin: auto;
    padding: 0 15px;
    box-sizing: content-box;

    position: relative;
    overflow: hidden;
    // padding-bottom: 20px;

    // font-family: Roboto, sans-serif;

    p:last-child {
      margin-bottom: 0;
    }
  }

  .text-section--with-line {
    max-width: $text-section-max-width + 40px; // 40px is padding from .text-section--with-line__content

    // margin-top: 80px;
    // margin-top: 40px;

    p {
      margin-top: 30px;
    }

    h2,
    p:first-of-type {
      margin-top: 40px;
    }
  }

  .text-section--with-line__content {
    padding: 0 20px;
    z-index: 3;
    position: relative;
  }

  ///////////////////////////////////
  // Взято из ImageOptimizer

  .text-section--with-line__line {
    position: absolute;
    background: rgb(64, 158, 255);

    top: 0%;
    height: 0%;
    width: 0%;

    transform: skewY(39deg);
    z-index: 1;
    // opacity: 0.2;

    .show-animation & {
    // .show-animation ^[-1..-1] {
      // animation: line 0.8s ease-in-out forwards;
    }

    // top: 36%;
    // height: 100%;
    // width: 100%;
    top: 0px;
    height: 50%;
    width: 85%;

    transform: skewY(23deg);
    background: linear-gradient(49.21deg, rgba(127, 127, 213, 0.2) 19.87%, rgba(134, 168, 231, 0.2) 58.46%, rgba(145, 234, 228, 0.2) 97.05%);

    &:before {
      content: '';
      width: 100%;
      height: 210px;
      display: block;
      position: absolute;
      right: 0;
      bottom: 0px;
      /* transform: skewY(-21deg); */

      background: red;
      /* background: #ffff; */
      /* background: linear-gradient(49.21deg, rgba(127, 127, 213, 0.2) 19.87%, rgba(134, 168, 231, 0.2) 58.46%, rgba(145, 234, 228, 0.2) 97.05%); */

      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
    }

    &:after {
      content: '';
      width: 200px;
      height: 100%;
      display: block;
      position: absolute;
      right: 0;
      bottom: 0px;
      top: 0;

      background: linear-gradient(150deg, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 1) 100%);
    }
  }

  @keyframes line {
    0% {
      width: 0%;
      top: 0%;
      height: 0%;
    }
    
    100% {
      width: 100%;
      top: 36%;
      height: 100%;
    }
  }

  .home__guide {
    display: flex;
    max-width: 600px;
    margin: auto;
    justify-content: space-between;
    align-items: center;
    text-align: center;

    margin-top: 30px;

    i {
      font-size: 50px;
      opacity: 0.7;
      font-weight: bold;
    }

    p {
      font-weight: bold;
      font-family: 'Roboto Mono', monospace;
      color: rgba(0, 0, 0, 0.7);
      margin-bottom: 0;
    }
    
    img {
      max-width: 200px;
      max-height: 200px;
    }
  }


  // ^^^ ImageOptimizer ^^^
  ///////////////////////////////////
  ///////////////////////////////////
  ///////////////////////////////////

  .card.el-card {
    box-shadow: none;
    border: 0;
    overflow: visible;
    // border-radius: 4px;
    border-radius: 15px;

    background: linear-gradient(49.21deg, rgba(127, 127, 213, 0.2) 19.87%, rgba(134, 168, 231, 0.2) 58.46%, rgba(145, 234, 228, 0.2) 97.05%);

    // .el-card__body {
    //   padding: 30px 40px;
    //   padding-top: 0;
    // }
  }

  .card--with-images.el-card {
    margin: 140px auto 100px;
    max-width: 1300px;
    // width: calc(100% - 30px);
    width: 100%;

    .el-card__body {
      padding-top: 130px;
      padding-bottom: 130px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }

    .card--with-images__text {
      max-width: 630px;
      margin-right: 70px;

      h2 {
        margin-bottom: 30px;
      }

      p {
        margin: 0;
      }
    }
  }

  .card--with-images__image {
    width: 400px;
    max-width: 100%;
    border-radius: 15px;
  }

  .pricing-section {
    // background: rgba(227, 230, 238, 0.5);
    background: rgba(227, 230, 238, 0.7);

    // padding: 110px 0;
    padding: 70px 0;

    margin: 100px 0 100px;
    margin-bottom: 0;

    p {
      margin: 0;
    }

    h3 {
      margin-top: 40px;
      margin-bottom: 12px;
    }

    h3:first-of-type {
      margin-top: 0;
    }
  }

  ul.pricing-section__pricing-list {
    // line-height: 36px;
    margin-top: 25px;
    margin-bottom: 0;
  }

  span.pricing-section__blurred-text {
    filter: blur(3px);
    background-color: #b9bbbe;
    color: transparent;
    border-radius: 3px;
    width: 30px;
    width: 50px;
    height: 20px;
    vertical-align: text-top;
    display: inline-block;
    cursor: pointer;
  }

  .pricing-section__popover {
    width: auto;
    min-width: 80px;

    .el-popover__title {
      margin: 0;
      font-family: 'Inconsolata', monospace;
      font-size: 18px;
    }
  }



  @media (max-width: 650px) {
    // hide all examples after 5
    .main-content__images-examples .twentytwenty-container:nth-child(n+6) {
      display: none;
    }

    .card--with-images.el-card {
      border-radius: 0;
      margin-top: 60px;
      margin-bottom: 60px;

      .el-card__body {
        padding-top: 35px;
        padding-bottom: 50px;
      }

      .card--with-images__text {
        margin: 0;

        h2 {
          margin-bottom: 15px;
        }
      }
    } // .card--with-images.el-card

    .card--with-images__image {
      margin-top: 40px;
    }

    .faq-section h5 {
      margin-top: 30px;
    }

    .main-content__images-examples {
      margin-top: 30px;
      margin-bottom: 60px;
    }

    .pricing-section {
      margin-top: 60px;
      padding: 45px 0;
    }
  } // @media (max-width: 650px)

</style>
